<template>
    <div class="terms-container">
        <!-- <navbar /> -->
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <p>
                        Thank you for choosing our travel package(s). By booking a trip through our website, you´re deemed to have agreed to its terms of use. Please read the following terms and procedures to make sure that you´ve clearly understood the conditions of your preferred trip. All the below mentioned terms and conditions are applicable for bookings made through our websites such as:
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>1. Pricing</h4>
                    <p>
                        Our policy assures you of an economic pricing, along with the convenience of making reservation online . Unless otherwise mentioned, prices quoted on our website are charged on per person basis, and do not include tips given to tour guides or drivers, passport / visa fee, travel insurance, drinks or food, accommodation, room services, and laundry. The published rates may change without prior notice, particularly in case of any unforeseen circumstance, such as increase in airline tickets, hotel rates, or transport expenses.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>2. Methods of Payment</h4>
                    <p>
                        Full payment can be done out of credit balance maintained with us. In case of insufficient balance, purchase can not be made and the balance has to be topped off first. 
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>3. Confirmation of Payment</h4>
                    <p>
                        Once the payment is done, our travel consultants will send you a confirmation slip by e-mail . Its print can be produced as evidence of payment to the service provider to redeem your tour package. But make sure that you´ve provided correct information pertaining to your travel requirements at the time of booking.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>4. Cancellation & No-Show Policy</h4>
                    <h5>4.1 Cancellation</h5>
                    <p>◾ Every Tour Activity/Attraction/Hotel has its own cancellation policies, before booking any activity with Desert Link Tours, client has to read cancellation  policy of that particular tour. <br/>
                    ◾ Combo tours can be cancelled, but if anyone is cancelling any of the single tour in combo then they will not able to avail combo offer, In this case Desert Link Tours will consider Individual tour price of non-cancelled tour & Refund remaining amount.<br/>
                    ◾ Tourist Visa fees is not Refundable once payment done to Desert Link Tours.<br/>
                    ◾ While booking combo please make sure that all combo tours options are available. If not, you would only be able to enjoy the tours and services that you have seen in the options.<br/>
                    </p>
                    <h5>4.2 No Show</h5>
                    <p>◾ If you fail to turn up for the tour, no refunds in part or full can be provided. The same condition applies in the case of unused tickets, sightseeing tours, car-rental or chauffeur-driven services. Likewise, rescheduling cannot be allowed for confirmed tours, transfers to and from airports, and other travel related services.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>5. Cancellation Procedures</h4>
                    <p>
                        For cancellation of all or any part of your reservation, make sure that you make notification of cancellation to Desert Link Tours in writing. Upon receiving your cancellation request, we´ll let you know through e-mail or telephone regarding the confirmation of booking cancellation as well as the fee that should be paid. Desert Link Tours cannot be held responsible for any cancellation that has not been received from you or not confirmed by us.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>6. Itinerary Amendments</h4>
                    <p>
                        Routings and services covered in your package are subject to change based on local / weather conditions, airway schedules and such other several aspects. Should this transpire, we can provide suitable options of similar value, however depending on its availability. At most, we announce changes in itinerary, if any, before departure. Please note that Desert Link Tourism reserves complete right to implement minor amendments in itinerary at any time without reimbursement. Further, no reimbursement can be done in the event of vis major, such as flood and earthquake.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>7. Travel Insurance</h4>
                    <p>
                        Desert Link Tourism shall not be responsible for any kind of damages as an outcome of accident, illness, injury, or loss of personal baggage or even the cancellation of trip. It is advisable that the traveler should avail of a travel insurance policy to tackle unforeseen events.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>8. Travel Documents</h4>
                    <p>
                        It is the responsibility of every guest to ensure that he or she carries documents relevant for a particular tour, including passport or a valid ID card. This is especially important for guests coming from a different country. No refund can be done in the event of loss or lack of these relevant documents. Similarly, passengers – irrespective of their nationality – are advised to check with their respective country´s consulate here to gain information on entry requisites before they plan to visit here. Equally vital is to inquire with your consulate regarding the current visa and health requirements, as they are subject to change without prior notice.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>9. Website Usage Restrictions</h4>
                    <p>
                        All content in this website, including logo, images, information on tour package, pricing details, and other relevant details, are proprietary to Desert Link Tourism. Accordingly, as a condition of this website´s usage, you agree not to exploit this website or its content for any non-personal, commercial, or illegitimate purposes.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>10. Delivery & Shipping Policy</h4>
                    <p>
                        Inclusion is mandatory and should have the below mentioned point (Applicable as per the website Products/Services).
                        Recommended statement is “(Website) will NOT deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of UAE”.
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12 col-md-12 col-lg-12">
                    <h4>11. Governing Law and Jurisdiction</h4>
                    <p>
                        Inclusion is mandatory and should have any one of the below mentioned points.
                        “Any purchase, dispute or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of UAE”.
                    </p>
                </div>
            </div>
        </div>
        <footer-home />
    </div>
</template>
<script>
import FooterHome from '../components/FooterHome.vue'
// import Navbar from '../components/Navbar.vue'
export default {
    data() {
        return {

        }
    },
    components: {
        // Navbar,
        FooterHome

    }
}
</script>
<style lang="scss" scoped>

.terms-container {
    height: 100%;
	 margin: 2rem 0;
   //  background: url('../assets/backgroundColor.png');
}

.row {
    margin: 15px;
    font-family: 'Open Sans', sans-serif;

    h4 {
        font-weight: 700;
    }

    h5 {
        font-weight: 500;
    }
    
    p {
        text-align: justify;
    }
}
</style>